<template>
<div class="uk-width-1-1">
  <div class="uk-background-default uk-padding-small">
    <div class="uk-form-stacked">
      <div class="uk-width-medium uk-margin">
        <label class="uk-form-label">Text wählen</label>
        <select class="uk-select" v-model="form.content_id">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {

  },
  data () {
    return {
      form: {}
    }
  },
  mounted () {
    this.form = this.data
  }
}
</script>
